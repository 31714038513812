<template>
  <div class="body">
    <!-- <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell> -->
    <van-form label-width="50px" @submit="onSubmit">
      <van-field
        v-model="inpatientName"
        center
        clearable
        label="患者姓名"
        required
        :rules="[{ required: true }]"
        placeholder="请填写住院患者姓名"
      >
      </van-field>
      <van-field
        v-model="inpatientNo"
        center
        clearable
        label="住院号"
        required
        :rules="[{ required: true }]"
        placeholder="请填写患者住院号"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            plain
            native-type="submit"
            style="margin-left: 10px"
            >查询</van-button
          >
        </template>
      </van-field>
    </van-form>
    <div v-show="recordDisplay" class="recordDisplay">
      <div class="content-box">
        <div class="content-state content-item">
          <span v-if="recordPatient.inPatientStatus == 1">住院中</span>
          <span v-if="recordPatient.inPatientStatus == 2">预出院</span>
          <span v-if="recordPatient.inPatientStatus == 3">已结账</span>
          <span v-if="recordPatient.inPatientStatus == 4">已出院</span>
          <span v-if="recordPatient.inPatientStatus == 5">已清账</span>
        </div>
        <div class="content-item">
          <span class="content-item-label"
            >患者姓名：{{ recordPatient.name }}</span
          >
        </div>
        <div class="content-item">
          <span class="content-item-label"
            >住院号：{{ recordPatient.inpno }}</span
          >
        </div>
        <div class="content-item">
          住院时间：
          {{ moment(recordPatient.inTime).format("YYYY-MM-DD") }}
          {{
            recordPatient.outTime
              ? "至 " + moment(recordPatient.outTime).format("YYYY-MM-DD")
              : "至今"
          }}
        </div>
        <div class="content-item">费用总计：{{ "￥" + total / 100 }}</div>
      </div>
      <div class="recordActiveBox">
        <van-field
          label="住院时间"
          v-model="timeValue"
          placeholder="选择住院时间进行筛选"
          clearable
          @focus="chooseDate()"
        >
          <template #button>
            <van-icon name="clear" @click="clearTimeValue()" v-if="timeValue" />
          </template>
        </van-field>
        <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
          <van-datetime-picker
            title="选择住院时间"
            v-model="currentDate"
            type="date"
            :min-date="minDate"
            :max-date="maxDate"
            @change="changeDate()"
            @confirm="confirmDate()"
            @cancel="cancelDate()"
          />
        </van-popup>
        <van-collapse v-model="recordActiveNames">
          <van-collapse-item v-for="(v, i) in recordList" :key="i" :name="i">
            <template #title>
              <span style="color: #006600">{{ v.name }}</span>
            </template>
            <template #value>
              <span style="color: #990000"
                >小计：￥{{ recordDetail[i] / 100 }}</span
              >
            </template>
            <van-cell-group
              v-for="(key, value, index) in v.children"
              :key="index"
              :title="value"
            >
              <van-cell
                v-for="(cItem, cIndex) in key"
                :key="cIndex"
                :title="cItem.detailName"
                :value="
                  '￥' +
                  (cItem.detailPrice / 100).toFixed(2) +
                  '*' +
                  cItem.detailCount
                "
              />
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div v-show="!recordDisplay" class="history-box">
      <div v-if="historyList">历史查询记录</div>
      <van-tag
        size="large"
        style="margin: 5px"
        v-for="(v, i) in historyList"
        :key="i"
        @click="hancleClickTag(v)"
      >
        {{ v.name }}({{ v.inpatientNo }})
      </van-tag>
    </div>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item, index)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />
        添加就诊人
      </div>
    </van-popup>
    <van-popup v-model="showPopup2" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择住院号</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in patList"
        :key="index"
        @click="choosePatientNo(item, index)"
      >
        <div v-if="item.id == inpnoId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.inpno + "-" + item.visitId + ")" }}
          <span v-if="item.inPatientStatus == 1">住院中</span>
          <span v-if="item.inPatientStatus == 2">预出院</span>
          <span v-if="item.inPatientStatus == 3">结账</span>
          <span v-if="item.inPatientStatus == 4">出院</span>
          <span v-if="item.inPatientStatus == 5">清账</span>
        </div>
        <div v-else>
          {{ item.name + "(" + item.inpno + "-" + item.visitId + ")" }}
          <span v-if="item.inPatientStatus == 1">住院中</span>
          <span v-if="item.inPatientStatus == 2">预出院</span>
          <span v-if="item.inPatientStatus == 3">结账</span>
          <span v-if="item.inPatientStatus == 4">出院</span>
          <span v-if="item.inPatientStatus == 5">清账</span>
        </div>
      </div>
      <div class="yyqr-choose-nodata" v-show="patList.length == 0">
        暂无住院号
      </div>
      <div class="yyqr-choose-add" @click="showPopup2 = false">取消</div>
    </van-popup>
    <van-dialog
      class="dialog"
      v-model="dialogShow"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>未查询到该住院号，请联系床管医生确认。</p>
      <van-button class="confirm-btn" @click="dialogShow = false"
        >我知道了</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "dailyChecklist",
  created() {
    let query = this.$route.query;
    this.inpatientNo = query.inpno || this.inpatientNo;
    this.inpatientName = query.name || this.inpatientName;
    if (this.inpatientNo && this.inpatientName) {
      this.onSubmit();
    }
  },
  watch: {
    $route: function ({ query }) {
      this.inpatientNo = query.inpno || this.inpatientNo;
      this.inpatientName = query.name || this.inpatientName;
    },
  },
  data() {
    return {
      inpnoId: "",
      showPopup: false, // 弹出层
      showPopup2: false, // 弹出层
      btnPatientLoading: false,
      chooseItem: "",
      total: "",
      personId: "",
      list: [],
      patList: [],
      inpatientNo: "", // 住院号
      inpatientName: "", // 患者姓名
      dialogShow: false,
      recordDisplay: false,
      recordList: {},
      recordDetail: [],
      recordPatient: {},
      moment: require("moment"),
      historyList: [],
      currentDate: new Date(),
      changedDate: new Date(),
      show: false, // 用来显示弹出层
      timeValue: "",
      recordActiveNames: [0],
    };
  },
  computed: {
    minDate() {
      if (this.recordPatient.inTime) {
        return new Date(this.recordPatient.inTime);
      }
    },
    maxDate() {
      if (this.recordPatient.outTime) {
        return new Date(this.recordPatient.outTime);
      } else {
        return new Date();
      }
    },
  },
  methods: {
    onSubmit() {
      if (!this.inpatientNo) {
        this.$notify({ message: "请填写患者住院号" });
        return;
      }
      // if (this.patList.length == 0) {
      //   this.dialogShow = true;
      //   return;
      // }
      if (
        this.$route.query.name != this.inpatientName &&
        this.$route.query.inpno != this.inpatientNo
      ) {
        this.$router.replace({
          query: {
            name: this.inpatientName,
            inpno: this.inpatientNo,
          },
        });
      }
      let param = {
        inpno: this.inpatientNo,
        name: this.inpatientName,
        time: this.timeValue,
      };
      let historyList = localStorage.getItem("historyList")
        ? JSON.parse(localStorage.getItem("historyList"))
        : [];
      historyList.unshift({
        inpatientNo: this.inpatientNo,
        name: this.inpatientName,
      });
      localStorage.setItem(
        "historyList",
        JSON.stringify(_.uniqBy(historyList, "inpatientNo"))
      );
      this.$http
        .get("/api/his/detail", param)
        .then((result) => {
          let tmp = [];
          for (let key in result.data.result) {
            tmp.push({
              name: key,
              children: result.data.result[key],
            });
          }
          this.total = result.data.total;
          this.recordDisplay = true;
          this.recordList = tmp;
          this.recordDetail = result.data.details;
          this.recordPatient = result.data.patient;
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.dialogShow = true;
            this.recordDisplay = false;
            this.timeValue = "";
          } else {
            this.$toast({ message: err.response.data });
            this.timeValue = "";
          }
        });
    },
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.showPopup = true;
        return;
      }
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    chooseJZPerson(item, index) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      sessionStorage.setItem("CurrentJzrIndex", index);
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
    },
    getList() {
      this.btnPatientLoading = true;
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.isHasPatient();
            // this.fetchPatList();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    choosePatientNo(item, index) {
      this.inpatientNo =
        this.patList[index].inpno + "-" + this.patList[index].visitId;
      this.inpnoId = item.id;
      this.showPopup2 = false;
    },
    fetchPatList() {
      let param = {
        // patid: "0010748614",
        patid: this.chooseItem.patId,
      };
      this.$http.get("/api/his/pat", param).then((result) => {
        this.patList = result.data;
        this.btnPatientLoading = false;
      });
    },
    hancleClickTag(v) {
      this.inpatientName = v.name;
      this.inpatientNo = v.inpatientNo;
      this.onSubmit();
    },
    chooseDate() {
      document.activeElement.blur();
      this.show = true;
    },
    changeDate() {
      this.changedDate = this.currentDate;
    },
    confirmDate() {
      this.timeValue = this.timeFormat(this.currentDate);
      this.onSubmit();
      this.show = false;
    },
    cancelDate() {
      this.show = false;
    },
    timeFormat(time) {
      return this.moment(time).format("YYYY-MM-DD");
    },
    clearTimeValue() {
      this.timeValue = "";
      this.onSubmit();
    },
  },
  mounted() {
    this.getList();
    this.historyList = JSON.parse(localStorage.getItem("historyList"));
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -1px;
  left: -2px;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.btn-query {
  width: 9rem;
  border-radius: 5px;
  border: none;
  font-size: 0.4rem;
  position: fixed;
  bottom: 0.4rem;
  left: 50%;
  transform: translate(-50%);
}
/deep/ .van-form {
  margin-top: 0;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-align: center;
  font-size: 0.4rem;
}
.confirm-btn {
  width: 50%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
.check-item-list {
  width: 9rem;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: #e6e6e6 0px 0px 10px;
  margin: 10px auto;
}
.check-item-title {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 0.38rem;
}
.recordDisplay {
  overflow: auto;
}
.tips-info {
  font-size: 0.32rem;
  text-align: center;
  color: #990000;
  font-weight: 600;
  line-height: 30px;
}
/deep/ .van-cell__title {
  text-align: left;
  font-size: 0.38rem;
}
/deep/ .van-cell-group__title {
  text-align: left;
  font-size: 0.38rem;
}
.content-box {
  overflow: auto;
  width: 9rem;
  margin: 10px auto;
  padding: 10px 16px;
  font-size: 0.38rem;
  text-align: left;
  background-color: #fff;
}
.content-item {
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.history-box {
  text-align: left;
  width: 9rem;
  margin: 10px auto;
  font-size: 0.38rem;
}
.recordActiveBox {
  width: 9rem;
  margin: 10px auto;
}
/deep/ .van-cell__title.van-field__label {
  width: 2rem !important;
  display: flex;
  align-items: center;
}
/deep/ .recordActiveBox > .van-cell::after {
  border-bottom: none;
}
</style>
